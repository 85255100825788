<template>
  <div class="flex min-h-screen flex-col text-txt-strongest">
    <Colors :colors="colors" />
    <div>
      <slot />
    </div>
  </div>
</template>

<script lang="ts" setup>
const { colors } = useColors()
</script>

<style></style>
